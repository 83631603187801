/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h2: "h2",
    a: "a",
    div: "div",
    p: "p",
    h3: "h3",
    code: "code"
  }, _provideComponents(), props.components), {Type} = _components;
  if (!Type) _missingMdxReference("Type", true);
  return React.createElement(React.Fragment, null, React.createElement(_components.h2, {
    id: "概要",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#%E6%A6%82%E8%A6%81",
    "aria-label": "概要 permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg viewBox=\"0 0 40 40\" xmlns=\"http://www.w3.org/2000/svg\"><path d=\"m12.4 0.842-4.44 38.3h6l4.44-38.3z\"/><path d=\"m26 0.842-4.44 38.3h6l4.44-38.3z\"/><path d=\"m4.23 23.8-0.695 6h30.7l0.695-6z\"/><path d=\"m5.8 10.2-0.695 6h30.7l0.695-6z\"/></svg>"
    }
  })), "概要"), "\n", React.createElement(_components.p, null, "Arrowsは、前後の矢印を生成・管理するためのコンポーネントです。このコンポーネントは", React.createElement(_components.a, {
    href: "/guides/events/"
  }, "いくつかのイベント"), "を配信します。"), "\n", React.createElement(_components.h2, {
    id: "プロパティ",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#%E3%83%97%E3%83%AD%E3%83%91%E3%83%86%E3%82%A3",
    "aria-label": "プロパティ permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg viewBox=\"0 0 40 40\" xmlns=\"http://www.w3.org/2000/svg\"><path d=\"m12.4 0.842-4.44 38.3h6l4.44-38.3z\"/><path d=\"m26 0.842-4.44 38.3h6l4.44-38.3z\"/><path d=\"m4.23 23.8-0.695 6h30.7l0.695-6z\"/><path d=\"m5.8 10.2-0.695 6h30.7l0.695-6z\"/></svg>"
    }
  })), "プロパティ"), "\n", React.createElement(_components.h3, {
    id: "arrows",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#arrows",
    "aria-label": "arrows permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg viewBox=\"0 0 40 40\" xmlns=\"http://www.w3.org/2000/svg\"><path d=\"m12.4 0.842-4.44 38.3h6l4.44-38.3z\"/><path d=\"m26 0.842-4.44 38.3h6l4.44-38.3z\"/><path d=\"m4.23 23.8-0.695 6h30.7l0.695-6z\"/><path d=\"m5.8 10.2-0.695 6h30.7l0.695-6z\"/></svg>"
    }
  })), React.createElement(_components.code, null, "arrows")), "\n", React.createElement(Type, {
    code: 'arrows: { prev?: HTMLButtonElement, next?: HTMLButtonElement }'
  }), "\n", React.createElement(_components.p, null, "前後の矢印のエレメントを保持しているオブジェクトです。HTML経由で渡されたもの、あるいはコンポーネント自身が生成したもののいずれかが入っています。"));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
